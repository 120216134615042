<template>
  <div id="Informations">
    <button v-show="!isLoading" class="button-filled mt-2 mb-1 my-auto float-right" @click="openReportModal"><font-awesome-icon icon="plus" class="icon" /></button>
    <h1 class="brand-color-dark headline">Informationen für den Fahrdienst</h1>
    
    <!-- <NoDataBox headline="Keine Informationen vorhanden">Schaue später wieder vorbei.</NoDataBox>

    <div class="content-card-container">
      <FileItem></FileItem>
      <FileItem></FileItem>
      <FileItem></FileItem>
      <FileItem></FileItem>
      <FileItem></FileItem>
      <FileItem></FileItem>
      <FileItem></FileItem>
    </div> -->

    <NoDataBox v-if="data.length <= 0 && !isLoading" headline="Keine Informationen vorhanden">Schaue später wieder vorbei.</NoDataBox>

    <p v-show="isLoading">
      <font-awesome-icon icon="spinner" class="icon" pulse /> Daten werden geladen...
    </p>

    <ModalBoardReport v-show="isModalVisible" @close="closeModal" :data="modalData" action="createInformationCorrection" />
    <ModalBoardReportCreator v-if="isReportModalVisible" @close="closeReportModal" />

    <div class="row grid grid-cols-1 gap-3" v-if="data">
      <FileItem @openReportModal="showModal" :reportable="true" :data="d" v-for="d in data.data" :key="d.id"></FileItem>
    </div>
  </div>
</template>


<script>
import NoDataBox from "@/components/NoDataBox";
import FileItem from "@/components/FileItem";
import ModalBoardReport from "@/components/ModalBoardReport";
import ModalBoardReportCreator from "@/components/ModalBoardReportInformationCreator";

import { mapGetters } from "vuex";

export default {
  name: "Informations",
  components: {
    NoDataBox,
    FileItem,
    ModalBoardReport,
    ModalBoardReportCreator
  },
  data() {
    return {
      data: [],
      isLoading: true,
      isModalVisible: false,
      isReportModalVisible: false,
      modalData: null,
    };
  },
  methods: {
    openReportModal(data) {
      this.isReportModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeReportModal(data) {
      this.isReportModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    showModal(data) {
      this.modalData = data
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.classList.remove("modal-open");
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store.dispatch("fetchBoardInformations").then((a) => {
      if (a.status == true) {
        this.data = a.result
      }

      this.isLoading = false
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>