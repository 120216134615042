<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Neue Information einreichen</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 2">
            <font-awesome-icon icon="check" class="icon color-success" />
            <p>Vielen Dank!<br>Wir werden Deine Meldung prüfen.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 1">
            <font-awesome-icon icon="spinner" class="icon" pulse />
            <p></p>
          </div>

          <div class="text-center flex flex-col gap-2" v-show="status == 0">
            <!-- {{data}} -->

            <div class="flex flex-col sm:flex-row gap-1 justify-between mb-4">
              <div class="flex flex-col gap-2 basis-1/2">
                <label>Station A</label>
                <select class="rounded-md text-center w-full py-1 text-black" @change="checkInputs" v-model="form.station_a">
                <option disabled selected value="-1">-</option>
                <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}} ({{station.shortname}})</option>
              </select>
              </div>

              <!-- <div class="flex flex-col gap-2 basis-1/4">
                <label>Richtung</label>
                <select class="rounded-md text-center w-full py-1 text-black" @change="checkInputs" v-model="form.direction">
                  <option disabled selected value="-1">-</option>
                  <option value="A">&#8594;</option>
                  <option value="B">&#8592;</option>
                  <option value="AB">&#8644;</option>
                </select>
              </div> -->

              <div class="flex flex-col gap-2 basis-1/2">
                <label>Station B</label>
                <select class="rounded-md text-center w-full py-1 text-black" @change="checkInputs" v-model="form.station_b">
                  <option disabled selected value="-1">-</option>
                  <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}} ({{station.shortname}})</option>
                </select>
              </div>
            </div>


            <div class="flex flex-col gap-2 w-full mb-4">
              <label>Titel</label>
              <!-- <select class="rounded-md text-center p-1 text-black" @change="checkInputs" v-model="form.reason">
                <option disabled selected value="-1">-</option>
                <option v-for="reason in reasons" :key="reason.id" :value="reason.id">{{reason.text}}</option>
              </select> -->
              <input type="text" @input="checkInputs" v-model="form.title" class="resize-y rounded-md text-black placeholder-gray-500 w-full text-center" placeholder="">
            </div>
            <div class="flex flex-col gap-2 w-full mb-4">
              <label>Beschreibung</label>
              <!-- <select class="rounded-md text-center p-1 text-black" @change="checkInputs" v-model="form.reason">
                <option disabled selected value="-1">-</option>
                <option v-for="reason in reasons" :key="reason.id" :value="reason.id">{{reason.text}}</option>
              </select> -->
              <textarea @input="checkInputs" v-model="form.message" class="resize-y rounded-md text-black placeholder-gray-500 w-full text-center" placeholder="Welche Informationen müssen ersetzt werden?"></textarea>
            </div>

            <div class="flex flex-col gap-2 w-full mb-4">
              <label>Linien</label>

              <div class="grid grid-cols-3 sm:grid-cols-8 gap-2 w-full">
              <label v-for="line in lines" :key="line.id"><input type="checkbox" :value="line.id" v-model="form.lines" @change="checkInputs"> {{line.name}}</label>
              </div>

              <!-- <br><br>{{form}} -->
            </div>

            <!-- <label><input type="radio" name="reportTyp" value="1" id="" v-model="form.typ" @change="changeTyp"> Aufgehoben</label> -->

            <button
              id="submitDataAddInformation"
              @click="sendAction()"
              class="button-filled text-center btn-fullsize w-full"
              :disabled="stepCompleteDisabled"
            >
              Information einreichen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalBoardReportCreator",
  data() {
    return {
      show: this.showModal,
      stations: [],
      lines: [],
      stepCompleteDisabled: true,
      form: {
        message: "",
        lines: [],
        station_a: -1,
        station_b: -1,
        direction: -1,
        title: ""
      },
      status: 0
    };
  },
  props: {
    image: String,
    showModal: false,
    data: null
  },
  mounted() {

    this.$store.dispatch("fetchStations").then((a) => {
      if (a.status == true) {
        this.stations = a.response
      }
    });

    this.$store.dispatch("fetchLines").then((a) => {
      if (a.status == true) {
        this.lines = a.response
      }
    });
  },
  methods: {
    checkInputs() {
      if(this.form.title.length > 3 &&
      this.form.message.length > 6 &&
      this.form.station_a != -1 &&
      this.form.station_b != -1) {
        this.stepCompleteDisabled = false
      } else {
        this.stepCompleteDisabled = true
      }
    },
    sendAction() {
      this.status = 1
      
      this.$store.dispatch("createInformationReport", this.form).then((a) => {
        if (a.status == true) {
          this.status = 2
        }
      });
    },
    close() {
      this.form = {
        message: "",
        lines: [],
        station_a: -1,
        station_b: -1,
        title: ""
      }
      this.status = 0
      this.$emit("close");
    },
  }
};
</script>




